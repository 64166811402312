.profile-container {
  .actions-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 32%;

    .clean-wrapper,
    .reset-wrapper {
      background: $color-white;
      padding: 30px 40px 30px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid $color-e8;
      margin-bottom: 30px;

      span.title {
        font-size: 17px;
        color: $color-a19;
        margin-bottom: 20px;
      }

      span {
        font-size: 14px;
        color: $color-7f;
        margin-bottom: 10px;
      }

      .description {
        width: 100%;
      }
    }

    .clean-wrapper {
      button {
        background-color: $color-red;
        color: $color-white;
      }
    }

    .reset-wrapper {
      button {
        background-color: $red;
        color: white;
      }
    }
  }
}
