.maintenance-container {
  display: table;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .maintenance-logo {
    background: url(../../../images/maintenance-icon.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 245px;
    width: 318px;
    margin-right: 20px;
  }
  .maintenance-text {
    display: table-cell;
    vertical-align: middle;
    div {
      color: #3c404b;
      font-size: 16px;
      line-height: 22px;
      &:first-child {
        color: #191a19;
        font-size: 30px;
        font-weight: 600;
        line-height: 38px;
        margin-bottom: 20px;
      }
    }
  }
}
