.card-footer__wrapper {
  padding: 10px 40px;
  position:absolute;
  bottom:0;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  width:400px;
  text-align: center;

  .btn:nth-of-type(2){
    margin-left:20px;
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .card-footer__wrapper {
    position: relative;
  }
}
