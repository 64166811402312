.btn {
  padding: 8px 30px;
  font-family: $font-primary;
  font-size: $font-size-base;
  background-color: $blue01;
  color: $color-white;
  border-radius: 4px;
  min-width: 125px;
  margin: 10px 0px;
  &--primary {
    background-color: $blue01;
    color: $color-white;
  }

  &--small {
    padding: 6px 20px;
    font-size: $font-size-small;
  }

  &--block {
    width: 100%;
  }

  &--secondary {
    color: $color-white;
    background: $red;
  }

  &--special {
    color: $color-19;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    background: transparent;
    text-transform: uppercase;
  }

  &--blue {
    color: $color-white;
    border: 1px solid $blue01;
    background: $blue01;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 14px;
  }

  &.cancel-btn {
    background-color: $red;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
