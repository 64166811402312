// auth pages layout

.auth-pages-container {
  .page-container {
    width: 100%;
    overflow: hidden;
  }

  .header-container {
    width: calc(100vw - 80px);
    margin: 0 auto;
  }

  .col-full {
    padding-left: 100px;
    max-width: 1200px;
    margin: 0 auto;
  }
}

a.reset-link {
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
.forgot-text {
  display: block;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  color: inherit;
}
