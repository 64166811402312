/////////////////////// SOURCE SANS PRO FONT ///////////////////////
@font-face {
  font-family: SourceSansPro;
  src: url('./fonts/SourceSansPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: SourceSansPro;
  src: url('./fonts/SourceSansPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/////////////////////// ROBOTO FONT ///////////////////////
@font-face {
  font-family: RobotoSlab;
  src: url('./fonts/Roboto-Slab/RobotoSlab-Regular.ttf') format('truetype');
  font-style: normal;
}

/////////////////////// YALDEVI FONT ///////////////////////
@font-face {
  font-family: YaldeviRegular;
  src: url('./fonts/Yaldevi/Yaldevi-Regular.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: YaldeviLight;
  src: url('./fonts/Yaldevi/Yaldevi-Light.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: YaldeviExtraLight;
  src: url('./fonts/Yaldevi/Yaldevi-ExtraLight.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: YaldeviMedium;
  src: url('./fonts/Yaldevi/Yaldevi-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: YaldeviBold;
  src: url('./fonts/Yaldevi/Yaldevi-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: YaldeviSemiBold;
  src: url('./fonts/Yaldevi/Yaldevi-SemiBold.ttf') format('truetype');
  font-style: normal;
}