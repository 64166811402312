html {
  font-size: 62.5%; /* set the base font size to 10 px */
}

body .app-container{
  @include font-size(1.4);

  font-family: YaldeviRegular;
  color: $text-color;
  line-height: $line-height-base;
}

body, html, #root {
  height: auto
}

body {
  margin: 0;
}

a {
  text-decoration: none;
}

h2 {
  font-weight: normal;
}


/* portlet */
.column-1, .portlet-column, .portlet-dropzone, .portlet-boundary, .portlet-content-container, .portlet-body {
  height: 100%;
}

.app-container {
  background: $blue02;
}
body,html {
  background: $color-gray-fc;
  display: flex;
  min-height: 100vh;
  flex-direction:column;

}

#app  {
  height: 100%;
  .Toastify{
    height: auto;
  }
  display: flex;
  flex: 1;
  & > div {
    flex:1;
    &> div {
      height:100%;
    }
  }
}
.app-container {
  display: flex;
  flex-direction: column;
  height:100%;
}

.page-container {
  width: calc(100vw - 80px);
  margin: 0 auto;
  flex: 1 1 auto;
}
/* input date/number */



input.number-input,
input.percentage {
  text-align:right;
}
input.date-input {
  direction:rtl;
  text-indent:15px;
  padding-right:0!important;
}

input:disabled,
select:disabled,
span:disabled {
  background: $disabled-field-color!important;
}
input.number-input[readonly],
input.percentage[readonly], {
  background: $disabled-field-color;
}
select {
  height: 40px;
}


/* has-currency placeholder */

.has-currency {
  position: relative;
  input {
    text-align: right;
    padding-left:30px!important;
  }
  .currency {
    margin-left:0!important;
    &:before {
      content: "€";
      display: flex;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%);
      max-height: 20px;
      max-width: 8px;
      align-items: center;
      color: #808BAC;
      font-size: 16px;
    }
  }
}
.customer-journey-upload-option {
  .has-currency {
    .currency {
      &:before {
        transform: translateY(0%);
      }
    }
  }
}

/*error*/

input.error, textarea.error {
  border: 1px solid $color-red!important;
}

/* placeholder */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #bbb;
  font-weight:300;
  font-family: $font-primary;
  font-style: italic;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #bbb;
  font-weight:300;
  font-family: $font-primary;
  font-style: italic;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #bbb;
  font-weight:300;
  font-family: $font-primary;
  font-style: italic;
}
:-moz-placeholder { /* Firefox 18- */
  color: #bbb;
  font-weight:300;
  font-family: $font-primary;
  font-style: italic;
}


/* checkbox */

.checkbox {
  margin-left: 0!important;
  input[type="checkbox"] {
      display: none;
      &+label {
          height: 20px;
          width: 20px!important;
          border: 1px solid #E8E8E8;
          border-radius: 4px;
          cursor: pointer;
          margin-bottom: 0;
          position:relative;


          &:after {
            content:"";
            opacity:0;
            border-radius: 4px;
            transform:scale(0);
            transform-origin:center;
            transition:0.3s ease-out;
            position:absolute;
            width:100%;
            height:100%;
            border-color: $blue01;
            background-color: $blue01;
            z-index:1;
          }
          svg {
            position:absolute;
            left:50%;
            top:50%;
            transform:translate(-50%,-50%);
            z-index:2;
            display:none
          }
      }
      &:checked+label {
        svg {
          display:block;
        }
        &:after {
         opacity:1;
         transform:scale(1)
        }
      }
  }
}

/* uploaded file box */
.app-container {
	.file__upload {
		>.file-uploaded {
			.has-file-checkmark {
				display: flex!important;
				align-items: center;
				justify-content: center;
			}
			.file-drop {
				.file-drop-target {
					.bottom {
						.upload-btn-wrapper {
							button {
								background: $color-3d!important
							}
						}
					}
				}
			}
		}
	}
}

/* Modal confirmation */

.ReactModalPortal .Overlay .Modal.confirmation {
  min-height: 200px;
  min-width: 500px;
  max-width: 500px;
  @include font-size(1.4);
  font-family: $font-primary;
  color: $text-color;
  line-height: $line-height-base;
  border:none;
  padding:0;

  .confirmation-dialog {
    font-family:$font-primary;
    padding:15px 0;
    border-radius:4px;
    border:1px solid $color-fe;
    .confirmation-title {
      font-size:17px;
      padding:0 15px;
    }
    .confirmation-text {
      margin-top:15px;
      padding:15px;
      border-top:1px solid $color-fe;
      border-bottom:1px solid $color-fe;
    }
    .confirmation-buttons {
      display:flex;
      justify-content: space-between;
      padding:15px 15px 0 15px;
      button {
        min-width:100px;
        border:1px solid $color-fe;
        background:transparent;
        font-size:14px;
        transition:0.3s ease-out;
        cursor:pointer;
        border-radius:4px;
        color:$color-white;
        outline:none;
        padding:5px 10px;
        &:first-child {
          background-color: $blue01;
        }
        &:last-child {
          background-color: $red;
        }
        &:hover {
          opacity:0.8;
        }
      }
    }
  }
}

.component-dialog {
  font-family: $font-primary;
  border-radius: 4px;
  border: 1px solid $color-fe;
  max-height: 90vh;
  overflow: auto;
  .component-topbar {
    font-size: 20px;
    padding: 30px;
    font-family: $font-primary;
    span.title {
      display:block;
      font-size: 16px;
      padding:0;
      color:$color-ac;
    }
  }

  .component-content {
    border-top: 1px solid $color-fe;
    border-bottom: 1px solid $color-fe;
    padding: 30px;
  }

  .component-footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;

    button {
      margin: 0;
    }
  }
}

textarea {
  resize: none;
}

.react-date-picker__inputGroup {
  display: flex;
}
