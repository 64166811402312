.content-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 40px;

  .sidemenu-container {
    flex: 0 0 19%;
    margin-right: 40px;
  }

  .charts-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    .chart-container {
      background: $color-white;
      border: 1px solid $color-fe;
      border-radius: 4px;
      margin-bottom: 40px;
    }

    // DONUT CHART
    .donut-chart {
      display: flex;
      justify-content: center;

      .donut-chart__chart {
        margin: 60px 80px;
      }

      .donut-chart__income,
      .donut-chart__expenses {
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        min-width: 230px;

        > a {
          color:  #1A1919;

          &:hover {
            text-decoration: none;
          }
        }

        .income__title
        {
          width:82%;
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          font-family: $yaldeviBold;
          align-content: space-between;

          span {
            font-size: 19px;
            line-height: 28px;
          }
          span:last-of-type {
            color: $blue01;
            margin-left: 15px;
          }
        }

        .expenses__title {
          width:82%;
          display: flex;
          flex-direction: row;
          margin-bottom: 20px;
          font-family: $yaldeviBold;
          align-content: space-between;

          span {
            font-size: 19px;
            line-height: 28px;
          }
          span:last-of-type {
            color: $red;
            margin-left: 15px;
          }
        }

        .income__group,
        .expenses__group {
          display: flex;
          align-items: center;
          cursor: pointer;

          span {
            font-size: 16px;
            line-height: 38px;
          }

          .icon-wrapper {
            height: 28px;
            width: 28px;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid $color-e8;
            border-radius: 50px;
            background: $grey01;
            transition: 0.3s ease-out;
            transform: translateZ(0);
          }
          &:hover {
            span {
              opacity: 0.8;
            }

            .icon-wrapper {
              box-shadow: 0 0 1px 1px $color-fe;
              transform: scale(1.1) translateZ(0);
            }
          }
        }
      }

      .donut-chart__income {
        align-items: flex-end;

        .income__title {
          text-align: right;
        }

        .income__group {
          font-family: $yaldeviMedium;
          margin-top:8px;

          .icon-wrapper {
            margin-left: 20px;
            

            &.fill {
              background: $blue01;
              border: 1px solid $blue01;
            }
          }
        }
      }

      .donut-chart__expenses {
        align-items: flex-start;

        .expenses__group {
          font-family: $yaldeviMedium;
          margin-top:8px;
          
          .icon-wrapper {
            margin-right: 20px;

            &.fill {
              background: $red;
              border: 1px solid $red;
            }
          }
        }
      }
    }
  }
}
.ReactModalPortal .Overlay .Modal.validate-nibud {
  top: 50px;
  height: 800px;
  min-height: 800px;
  overflow-y: auto;
  .expense-description {
    font-size: 14px;
  }
  #step2-form{
    padding-bottom: 10em;
  }
}

.inactive-option
{
  color: $grey01;
}
