body {
  background: $color-gray-fc;
}
.app-container {
  display: flex;
  flex-direction: column;
}

.page-container {
  width: 1430px;
  margin: 0 auto;
}

.footer--app {
  padding: 0;
  margin: 0;
}

// cookie consent banner

.cookieConsent {
  display: flex;
  align-items: baseline;
  background: $color-header-grey;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 999;
  bottom: 0px;
  padding: 40px 40px;
  align-items: flex-start;
  color: $color-white;

  > div {
    .cookie-title,
    .cookie-text {
      display: block;
      font-family: $font-primary;
      margin-bottom: 20px;
    }

    .cookie-text {
      font-weight: 300;
    }
  }
}
