$laptop-large: 1600px;
$laptop-small: 1024px;
$desktop: 991px;
$tablet: 767px;
$handheld: 568px;

/* colors */
$color-gray: #333;

/* typography */
$font-size-base-unit: 1.6;
$line-height-base: 1.5;
$text-color: #081234;


// Typography
$font-primary: YaldeviRegular;
$font-size-base: 14px;
$font-size-medium: 1em;
$font-size-big: 18px;
$font-size-small: 12px;

// Colors
$color-header-grey:#3C404B;
$color-white: #fff;
$color-light-grey: #E8E8E8;
$color-green: #4BB694;
$disabled-input: #dddddd;

$color-primary: #386DFF;
$color-gray-fc: #f7f8fc;
$color-fe: #E4EAFE;
$color-e8: #E8E8E8;
$color-94: #4BB694;
$color-red: #F24452;
$color-77: #777777;
$color-3d: #33363D;
$color-19: #1A1919;
$color-ac: #808BAC;
$color-b4: #41CA99;
$color-a19: #191A19;
$color-7f: #7F7F7F;
$color-de: #bbc4de;
$color-fb: #F7F8FB;
$color-de: #dedede;
$color-83: #E8E683;
$color-89: #F24452;
$color-f9: #F9F9F9;
$color-4b: #3C404B;
$color-b1: #A7B5B1;
$color-admin: #FBFCFE;
$color-21: #212422;

$color-income: #386DFF;
$color-expense: #F24452;
$color-disable: #E8E683;

$disabled-field-color: #DDDDDD;

$screen-lg-min: 1400px;

$color-red-error:  #F24452;
$color-yellow-warn: #E8E683;
$color-green-success: #4BB694;
$color-blue-info: #41CA99;

$dot-default-color: #bbb;
$dot-processing-color: #CCE5FF;
$dot-failed-color: #e88289;
$dot-finish-color: #4bb694;
$dot-warning-color: #FDF3CD;
$dot-success-color: #10a54a;

// Home-Page
$hp-heading-color: #061a06;
$hp-text-color: #191a19;
$hp-separator-color: #a6aec4;
$hp-background-sections: #F9FAFD;
$hp-white: #ffffff;
$hp-container-padding: 10%;

$footer-background: #3C404C;

// fonts
$hp-primary-font: YaldeviRegular;
$hp-font: YaldeviRegular;
$yaldeviRegular: YaldeviRegular;
$yaldeviLight: YaldeviLight;
$yaldeviExtraLight: YaldeviExtraLight;
$yaldeviMedium: YaldeviMedium;
$yaldeviBold: YaldeviBold;
$yaldeviSemiBold: YaldeviSemiBold;

//redesign colors
$black100: #081234;
$blue01: #386DFF;
$blue02: #F2F9FF;
$blue03: #2649AA;
$blue04: #2C375F;
$red: #F24452;
$red02: #C93540;
$white: #FFFFFF;
$grey01: #BCC3CC;
$grey02: #E7EAF1;
$grey03: #F7F8FA;
$grey04: #2C375F;
$green: #41CA99;

// Small tablets and large smartphones (portrait view)
$screen-xs-min: 375px;
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 767px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;


$border-svg: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
$border-svg-animated-1: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");

$border-svg-animated-2: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");

$border-svg-animated-3:url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='black' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='21' stroke-linecap='square'/%3e%3c/svg%3e");