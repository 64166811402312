.dropdown-menu {
  padding: 25px 30px;
  background: $blue04;
  right: 0;
  left: auto;
  min-width: 270px;
  top: 5px!important;
  border-radius: 8px;

  .user-menu__item {
    text-decoration: none;

    a, span {
      font-family: $font-primary;
      font-size: 16px;
      color: $color-white;
      font-weight: 400;

      &:hover {
        text-decoration: none;
      }
    }
  }
}