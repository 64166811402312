@import './mixins';
@import './variables';
@import './_general';
@import './_fonts';

// common
@import '../components/common/Button/style';
@import '../components/common/Navigation/UserMenu/style';
@import '../components/common/Tooltip/style';
@import '../components/common/Footer/style';
@import '../components/common/Loader/style';
@import '../components/common/DatePickers/style';
@import '../components/common/RenderCJRadio/style';
@import '../components/common/RichTextMarkdown/style';
@import '../components/common/NotFound/style';

@import '../components/common/Upload/Tax/style';

// AUTH PAGES
@import '../components/pages/Auth/style';
@import '../components/pages/Auth/Login/style';
@import '../components/pages/Auth/CreateUser/style';
@import '../components/pages/Auth/ChangePassword/style';
@import '../components/pages/Auth/ResetPassword/style';
@import '../components/pages/Auth/ForgotPassword/EnterEmail/style';

// APP PAGES
@import '../components/pages/App/style';
@import '../components/pages/App/Overview/style';
@import '../components/pages/App/Overview/Charts/YearlyOverviewChart/Chart/YearlyOverviewTooltip/style';
@import '../components/pages/App/Overview/Charts/YearlyOverviewChart/style';
@import '../components/pages/App/Overview/Charts/MonthlyOverview/DonutChart/style';
@import '../components/pages/App/Overview/Tips/style';
@import '../components/pages/App/Overview/PersonalSituation/style';
@import '../components/pages/App/Overview/Retirement/style';
@import '../components/pages/App/Overview/Cards/Header/style';
@import '../components/pages/App/Overview/Cards/Footer/style';
@import '../components/pages/App/Overview/Cards/Content/style';
@import '../components/pages/App/UserProfile/style';
@import '../components/pages/App/GenerateTaxFiles/style';
@import '../components/pages/App/UserProfile/UserDetails/style';
@import '../components/pages/App/UserProfile/UserActions/style';

@import '../components/pages/App/CustomerJourney/style';
@import '../components/pages/App/CustomerJourney/JourneyStep1/style';
@import '../components/pages/App/CustomerJourney/JourneyStep2/style';
@import '../components/pages/App/SaveUserData/style';

// PUBLIC PAGES
@import '../components/pages/Public/LandingPages/style';
@import '../components/pages/Public/LandingPages/common/Header/style';
@import '../components/pages/Public/LandingPages/common/Footer/style';
@import '../components/pages/Public/LandingPages/About/style';
@import '../components/pages/Public/LandingPages/Faq/style';
@import '../components/pages/Public/LandingPages/PrivacyPolicy/style';

// ADMIN PAGES
@import '../components/pages/Admin/common/style';
@import '../components/pages/Admin/Users/style';
@import '../components/pages/Admin/Organisations/style';
@import '../components/pages/Admin/Reports/style';
@import '../components/pages/Admin/Homepage/style';
@import '../components/pages/Admin/AuditLogs/style';
@import '../components/pages/Admin/EmailTemplates/style';

// 3RD PARTY
@import '../../node_modules/rc-slider/assets/index.css';
